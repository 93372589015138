import Head from 'next/head';
import Image from 'next/image';
import styled from 'styled-components';
import clearMeLogo from '../public/clearme_logo_results.svg';

const Container = styled.div`
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  padding: 20px 15px 20px 15px;
  text-align: center;
  max-width: 600px;
  color: white;

  h1, h2 {
    color: #C6E2F7;
  }
`;


const globalStyle = `
  body {
    background-color: #072d3e;
  }
`;

export default function Home () {
  return (
    <div>
      <Head>
        <title>Clearme</title>
        <link rel="icon" href="/favicon.ico" />
        <style>{globalStyle}</style>
      </Head>

      <Container>
        <Image src={clearMeLogo} width={120} height={120} layout="fixed" alt="ClearMe" unoptimized />
        <h1>
          <a href="https://clearme.ca">Clearme</a>
        </h1>
      </Container>
    </div>
  )
}
